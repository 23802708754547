body {
  margin: 0;
  font-family: 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
    flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Almendra&family=Bitter:wght@400;700&family=Darumadrop+One&family=Open+Sans:wght@400&display=swap');



a {
  color: black;
}

p {
  font-size:  1.0em
}

h1 {
  font-family:'Bitter', serif;
  font-size: 3em;
}

h2 {
  font-size: 1.5em;
  font-family:'Bitter', serif;
  font-weight: 400;
}

h3 {
  font-size: 1.5em;
  font-family:'Bitter', serif;
}


h5 {
  font-family:'Bitter', serif;
  text-decoration: none;
}

.centered {
  height: 100%;
  text-align: center;

}
li {
  margin-left: 0;
}



